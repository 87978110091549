/** font_0   Site Title  Title
    font_2  Page Title  Page-title
    font_3  Huge Heading    Heading-XL
    font_4  Large Heading   Heading-L
    font_5  Basic Heading   Heading-M
    font_6  Small Heading   Heading-S
    font_7  Paragraph 1 Body-L
    font_8  Paragraph 2 Body-M
    font_9  Paragraph 3 Body-S
**/
import { ISiteTextPreset } from '@wix/native-components-infra/dist/src/types/wix-sdk';

const DefaultDesign = {
  questionText: {
    color: {
      name: 'questionTextColor',
      dataHook: 'questionTextColorPicker',
      type: 'ColorPickerInput',
      value: 'color-5',
    },
    font: {
      name: 'questionTextFont',
      type: 'FontPicker',
      value: 'Body-L' as keyof ISiteTextPreset,
      theme: 'font_7',
      hideTheme: false,
      showHTMLTag: true,
      htmlTag: 'h3',
      startWithSize: 18,
      startWithMobileSize: 20,
      fontMaxSize: 40,
      hideStyle: false,
    },
  },
  questionStripesText: {
    color: {
      name: 'questionStripesTextColor',
      dataHook: 'questionStripesTextColorPicker',
      type: 'ColorPickerInput',
      value: 'color-5',
    },
    font: {
      name: 'questionStripesTextFont',
      type: 'FontPicker',
      value: 'Heading-S' as keyof ISiteTextPreset,
      theme: 'font_6',
      hideTheme: false,
      showHTMLTag: true,
      htmlTag: 'h3',
      startWithSize: 25,
      fontMaxSize: 40,
      hideStyle: false,
    },
  },
  answerText: {
    color: {
      name: 'answerTextColor',
      dataHook: 'answerTextColorPicker',
      type: 'ColorPickerInput',
      value: 'color-5',
    },
    font: {
      name: 'answerTextFont',
      type: 'FontPicker',
      value: 'Body-M' as keyof ISiteTextPreset,
      theme: 'font_8',
      hideTheme: false,
      showHTMLTag: false,
      startWithSize: 16,
      fontMaxSize: 40,
      hideStyle: true,
    },
  },

  answersLinks: {
    color: {
      name: 'answersLinksColor',
      dataHook: 'answersLinksColorPicker',
      type: 'ColorPickerInput',
      value: 'color-8',
      value2: 'color-5',
    },
  },
  questionNumberText: {
    color: {
      name: 'questionNumberTextColor',
      dataHook: 'questionNumberTextColorPicker',
      type: 'ColorPickerInput',
      value: 'color-fill-background-primary',
    },
    font: {
      name: 'questionNumberTextFont',
      type: 'FontPicker',
      value: 'Body-M' as keyof ISiteTextPreset,
      theme: 'font_2',
      hideTheme: false,
      showHTMLTag: false,
      startWithSize: 20,
      startWithMobileSize: 60,
      fontMaxSize: 60,
    },
  },
  questionNumberHover: {
    color: {
      name: 'questionNumberHover',
      dataHook: 'questionNumberHoverPicker',
      type: 'ColorPickerInput',
      value: 'color-8',
      opacity: 1,
    },
  },
  questionNumberBgColor: {
    color: {
      name: 'questionNumberBgColor',
      dataHook: 'questionNumberBgColorPicker',
      type: 'ColorPickerInput',
      value: 'color-action',
      opacity: 1,
    },
  },
  numberQuestionsLayoutBorder: {
    color: {
      name: 'numberQuestionsLayoutBorderColor',
      dataHook: 'numberQuestionsLayoutBorderColorPicker',
      type: 'ColorPickerInput',
      value: 'color-5',
      opacity: 0.75,
    },
    border: {
      name: 'numberQuestionsLayoutBorderWidth',
      type: 'slider',
      min: 0,
      max: 6,
      unit: 'px',
      value: 0,
    },
  },
  numberAnswersLayoutBorder: {
    color: {
      name: 'numberAnswersLayoutBorderColor',
      dataHook: 'numberAnswersLayoutBorderColorPicker',
      type: 'ColorPickerInput',
      value: 'color-line',
      opacity: 0.3,
    },
    border: {
      name: 'numberAnswersLayoutBorderWidth',
      type: 'slider',
      min: 0,
      max: 6,
      unit: 'px',
      value: 1,
    },
  },
  numberAnswersLayoutBgColor: {
    name: 'numberAnswersLayoutBgColor',
    dataHook: 'numberAnswersLayoutBgColorPicker',
    type: 'ColorPickerInput',
    value: 'color-fill-background-primary',
    opacity: 1,
  },
  headerText: {
    color: {
      name: 'headerTextColor',
      dataHook: 'headerTextColorPicker',
      type: 'ColorPickerInput',
      value: 'color-5',
    },
    font: {
      name: 'headerTextFont',
      type: 'FontPicker',
      value: 'Heading-M' as keyof ISiteTextPreset,
      theme: 'font_5',
      hideTheme: false,
      showHTMLTag: true,
      htmlTag: 'h2',
      fontMaxSize: 100,
      startWithSize: 32,
      startWithMobileSize: 24,
    },
  },
  categoryBasicText: {
    color: {
      name: 'categoryBasicTextColor',
      dataHook: 'categoryBasicTextColorPicker',
      type: 'ColorPickerInput',
      value: 'color-5',
    },
    font: {
      name: 'categoryBasicTextFont',
      type: 'FontPicker',
      value: 'Body-L' as keyof ISiteTextPreset,
      theme: 'font_7',
      hideTheme: false,
      showHTMLTag: false,
      fontMaxSize: 40,
      startWithSize: 14,
    },
  },
  categoryOtherText: {
    color: {
      name: 'categoryOtherTextColor',
      dataHook: 'categoryOtherTextColorPicker',
      type: 'ColorPickerInput',
      value: 'color-5',
    },
    font: {
      name: 'categoryOtherTextFont',
      type: 'FontPicker',
      value: 'Body-S' as keyof ISiteTextPreset,
      theme: 'font_9',
      hideTheme: false,
      showHTMLTag: false,
      fontMaxSize: 40,
      startWithSize: 14,
    },
  },
  selectedCategory: {
    color: {
      name: 'selectedCategoryTextColor',
      dataHook: 'selectedCategoryTextColorPicker',
      type: 'ColorPickerInput',
      value: 'color-8',
      value2: 'color-5',
      opacity: 1,
    },
  },
  stripColor: {
    color: {
      name: 'stripColor',
      dataHook: 'stripColorPicker',
      type: 'ColorPickerSlider',
      value: 'color-8',
      opacity: 0.2,
    },
  },
  widget: {
    color: {
      name: 'widgetBgColor',
      dataHook: 'widgetBgColorPicker',
      type: 'ColorPickerSlider',
      value: 'color-1',
      opacity: 1,
    },
  },
  dividerCollapse: {
    color: {
      name: 'stripColor',
      dataHook: 'ColorPickerSliderPicker',
      type: 'ColorPickerSlider',
      value: 'color-8',
      opacity: 1,
    },
    border: {
      name: 'dividerWidth',
      type: 'slider',
      min: 1,
      max: 6,
      unit: 'px',
    },
  },
  mobile: {
    title: {
      font: {
        name: 'mobileTitleFontSize',
        startWithSize: 20,
        fontMinSize: 12,
        fontMaxSize: 40,
      },
    },
    category: {
      font: {
        name: 'mobileCategoryFontSize',
        startWithSize: 16,
        fontMinSize: 12,
        fontMaxSize: 24,
      },
    },
    questionsNumber: {
      font: {
        name: 'mobileQuestionsNumberFontSize',
        startWithSize: 50,
        fontMinSize: 12,
        fontMaxSize: 60,
      },
    },
    questions: {
      font: {
        name: 'mobileQuestionsFontSize',
        startWithSize: 16,
        fontMinSize: 12,
        fontMaxSize: 24,
      },
    },
    answers: {
      font: {
        name: 'mobileAnswersFontSize',
        startWithSize: 14,
        fontMinSize: 12,
        fontMaxSize: 24,
      },
    },
  },
};

export default DefaultDesign;
