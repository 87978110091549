import React, { useContext, useEffect } from 'react';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { Accordion, AccordionTheme } from 'wix-ui-tpa/cssVars';
import SocialWrapper from '../SocialWrapper/SocialWrapper';
import { st, classes } from './accordion.st.css';
import { st as wst, classes as wclasses } from '../../Widget.st.css';
import RCV from './RCV';
import { DraftJSObject } from '../../helpers/Interfaces';
import { useSettings } from '@wix/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import settingsParams, {
  ItemAppearance,
  OpenQuestionIcon,
} from '../../../settingsParams';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { openIconBySettings } from '../../shared/common';

const AccordionOneColumn = ({
  filteredQuestions,
}: {
  filteredQuestions: QuestionEntry[];
}) => {
  const { firstQuestionId } = useContext(AppContext);
  const settings: ISettingsContextValue = useSettings();
  const itemAppearance = settings.get(settingsParams.itemAppearance);
  const itemsApperance: AccordionTheme =
    itemAppearance === ItemAppearance.BOX
      ? AccordionTheme.Box
      : AccordionTheme.Line;
  const noAnimation = !settings.get(settingsParams.enableScrollAnimation);
  const openOneAnswer = settings.get(settingsParams.openOneAnswer);

  if (!filteredQuestions.length) {
    return <></>;
  }
  const stClassAccordion = st(classes.flexAccordion, {
    noAnimation,
    itemAppearance,
  });
  const openQuestionIcon =
    settings?.get(settingsParams.openQuestionIcon) || OpenQuestionIcon.ARROW;
  const icons = openIconBySettings(openQuestionIcon);

  return (
    <Accordion
      key={`accordion-${firstQuestionId}-${filteredQuestions.at(-1)?.id}`}
      className={st(classes.root, stClassAccordion)}
      icons={icons}
      multiple={!openOneAnswer}
      initiallyExpanded={`${firstQuestionId}`}
      theme={itemsApperance}
    >
      {filteredQuestions.map((item: QuestionEntry) => {
        const { id, question, draftjs } = item;
        const parsedDraftjs: DraftJSObject = JSON.parse(draftjs!);

        return (
          <Accordion.Item
            key={id!}
            id={id!}
            title={question!}
            suffix={<div className={wst(wclasses.hidesuffix)}></div>}
          >
            {parsedDraftjs && <RCV convertAnswerToJSON={parsedDraftjs} />}
            <SocialWrapper questionId={id!} />
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default AccordionOneColumn;
