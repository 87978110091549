import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import React, { useContext } from 'react';
import {
  Accordion,
  AccordionSize,
  useTPAComponentsContext,
} from 'wix-ui-tpa/cssVars';
import SocialWrapper from '../SocialWrapper/SocialWrapper';
import AccordionNumberStrip from './AccordionNumberStrip';
import { st, classes } from '../../Widget.st.css';
import settingsParams from '../../../settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import {
  st as accordionStyle,
  classes as accordionClasses,
} from './numbered.st.css';
import RCV from './RCV';
import { DraftJSObject } from '../../helpers/Interfaces';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { openIconBySettings } from '../../shared/common';

const NumberOneColumn = ({
  filteredQuestions,
  indexes,
}: {
  filteredQuestions: QuestionEntry[];
  indexes: number[];
}) => {
  const { mobile } = useTPAComponentsContext();
  const settings = useSettings();
  const noAnimation = !settings.get(settingsParams.enableScrollAnimation);
  const { firstQuestionId } = useContext(AppContext);
  const stRootClass = accordionStyle(accordionClasses.root, { noAnimation });

  const openQuestionIcon = settings?.get(settingsParams.openQuestionIcon);
  const icons = openIconBySettings(openQuestionIcon);
  const transparentDivider = accordionStyle(
    accordionClasses.transparentDivider,
    { mobile },
  );
  const { gap } = settings.get(settingsParams.spacing);

  return (
    <ul
      className={st(classes.numberLayout, {
        mobile,
      })}
    >
      {filteredQuestions.map((item: QuestionEntry, index) => {
        const { id, question, draftjs } = item;
        const parsedDraftjs: DraftJSObject = JSON.parse(draftjs!);

        return (
          <li
            className={st(classes.numberItem, { novspace: gap === 0 })}
            key={id!}
          >
            <AccordionNumberStrip index={indexes[index] - 1} />
            <Accordion
              size={AccordionSize.Small}
              key={`numbered-${firstQuestionId}-${id}`}
              className={accordionStyle(stRootClass, transparentDivider)}
              initiallyExpanded={`${firstQuestionId}`}
              icons={icons}
            >
              <Accordion.Item
                key={id!}
                id={id!}
                title={question!}
                suffix={<div className={st(classes.hidesuffix)}></div>}
              >
                {parsedDraftjs && <RCV convertAnswerToJSON={parsedDraftjs} />}

                <SocialWrapper questionId={id!} />
              </Accordion.Item>
            </Accordion>
          </li>
        );
      })}
    </ul>
  );
};

export default NumberOneColumn;
